import React, { useState } from 'react'
import { Action, Search, SearchValue } from './types'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import { styleTextField } from '../../common/styles/TextFieldStyle'

type SearchProps<Q> = {
  readonly search: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  disabled?: boolean
  isPreventDefault?: boolean
}

export function Searcher<Q extends Record<string, any>>(props: SearchProps<Q>) {
  const [values, setValues] = useState<SearchValue<Q>[]>(
    props.search.searchValues,
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (props.isPreventDefault) {
      e.preventDefault()
    }
    props.search.handleSearch([...values])
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name === sv.name)!.value = e.target.value
    setValues([...values])
    props.search.handleSearch([...values])
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Box marginBottom="5px">
        <Box display="flex" justifyContent={'flex-end'} flexWrap="wrap">
          {values.map((v) => (
            <Box
              mr={2}
              width={v.width}
              marginBottom={'5px'}
              marginRight={'5px'}
              key={v.name as string}
            >
              <TextField
                disabled={props.disabled}
                sx={styleTextField}
                name={v.name as string}
                label={v.label}
                style={
                  window.innerWidth < 378
                    ? { width: '120px', fontFamily: 'Roboto-Light' }
                    : window.innerWidth < 490
                    ? { width: '150px', fontFamily: 'Roboto-Light' }
                    : { width: '220px', fontFamily: 'Roboto-Light' }
                }
                onChange={handleChange}
                type={v.type ?? 'text'}
                value={v.value ?? ''}
                variant="outlined"
                size="small"
              />
            </Box>
          ))}
        </Box>
      </Box>
    </form>
  )
}
