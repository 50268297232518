import { Container, ContainerItem } from '../common/container/Container'
import { FileService } from '../modules/files/services/FileService'
import {
  FILE_API_KEY,
  FILE_SERVICE_KEY,
  FILES_MODULE,
  FilesProps,
} from '../modules/files/container'
import { FileApi } from '../modules/files/api/FileApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: FilesProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(FILE_API_KEY, new ContainerItem({ instance: new FileApi() }))

  items.set(
    FILE_SERVICE_KEY,
    new ContainerItem({ instance: new FileService({ apiKey: FILE_API_KEY }) }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getFileContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[FILES_MODULE],
      },
    })
  }

  return container
}
