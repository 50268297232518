import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n/i18n'
import i18next from './i18n/i18n'
import { BrowserRouter as Router } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const element1 = document.getElementById('contact')

if (element1) {
  element1.innerHTML = i18next.t('contactUS')
}

const element2 = document.getElementById('privacy')

if (element2) {
  element2.innerHTML = i18next.t('privacyCookies')
}

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
