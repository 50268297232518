import React, { useEffect, useState } from 'react'
import { Box, SelectChangeEvent } from '@mui/material'
import { PhoneData } from './PhoneData'
import { PhoneNumberInput } from './PhoneNumberInput'
import { CountrySelect } from './CountrySelect'

interface PhoneCodeSelectorProps {
  onSelectCode: (code: string) => void
  handlePressKey: (event: React.KeyboardEvent<HTMLDivElement>) => void
  phone: string
}

export const PhoneCodeSelector: React.FC<PhoneCodeSelectorProps> = ({
  onSelectCode,
  handlePressKey,
  phone,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string>('ES')
  const [phoneNumber, setPhoneNumber] = useState<string>('+34')

  useEffect(() => {
    if (phone === '') {
      setPhoneNumber('+34')
    }
  }, [phone])

  const handleCountryChange = (event: SelectChangeEvent) => {
    setSelectedCountry(event.target.value)
    setPhoneNumber(PhoneData[event.target.value])
  }

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value
    const countryCode = PhoneData[selectedCountry]

    if (inputValue.startsWith(countryCode)) {
      setPhoneNumber(inputValue)
      onSelectCode(inputValue.split(' ').join(''))
    } else {
      event.target.value = phoneNumber
    }
  }

  const handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void = (
    event,
  ) => {
    if (!event) return
    const inputValue = event.currentTarget.value
    const countryCode = PhoneData[selectedCountry]
    if (
      event.key === 'Backspace' &&
      inputValue !== countryCode &&
      event.currentTarget.selectionStart &&
      event.currentTarget.selectionStart <= 3
    ) {
      return event.preventDefault()
    }
    handlePressKey(event)
    if (event.key === 'Enter') {
      event.preventDefault()
      setPhoneNumber('+34')
    }
  }

  return (
    <Box
      mt={4}
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      width={'100%'}
    >
      <CountrySelect
        selectedCountry={selectedCountry || 'ES'}
        handleCountryChange={(e) => handleCountryChange(e)}
      />
      <PhoneNumberInput
        phoneNumber={phoneNumber}
        handlePhoneNumberChange={handlePhoneNumberChange}
        handleOnKeyDown={handleOnKeyDown}
        selectedCountry={selectedCountry}
      />
    </Box>
  )
}
