import { Container } from '../../common/container/Container'

export type UserContainerConfig = {
  moduleFullUrl: string
}

export type UserProps = {
  parentContainer: Container
  config: UserContainerConfig
}

export const USERS_MODULE = Symbol('USERS_MODULE')
export const USER_API_KEY = Symbol('USER_API_KEY')
export const USER_SERVICE_KEY = Symbol('USER_SERVICE_KEY')
export const LOGGED_USER_SERVICE_KEY = Symbol('LOGGED_USER_SERVICE_KEY')
export const ROLES_MODULE = Symbol('ROLES_MODULE')
export const ROLES_API_KEY = Symbol('ROLES_API_KEY')
export const ROLES_SERVICE_KEY = Symbol('ROLES_SERVICE_KEY')
