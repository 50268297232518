import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import styles from './InvitationCode.module.scss'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { URL_DASHBOARD } from '../../routes/routes-constants'
import { Box, Modal } from '@mui/material'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'
import { CustomModal } from '../../components/modal/CustomModal'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const InvitationCode = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [timeToExpire, setTimeToExpire] = useState<string>('')
  const [welcomeModalOpened, setWelcomeModalOpened] = useState<boolean>(false)
  const { setGoodbyeModal, setGlobalCode, globalCode } = useGlobalContext()

  useEffect(() => {
    const code = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    )
    setGlobalCode(code)

    loggedUserService.loginGuest(code).subscribe((res) => {
      if (!res) {
        enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
      } else {
        const resSessionExpires = new Date(res.sessionExpires).getTime()
        const currentDate = new Date().getTime()
        const differenceInMilliseconds = resSessionExpires - currentDate
        const minutes = differenceInMilliseconds / (1000 * 60)

        setTimeToExpire(minutes.toFixed(0) + ' ' + t('minutes'))
        setTimeout(() => {
          setGoodbyeModal(true)
          loggedUserService.logout()
        }, differenceInMilliseconds)

        setWelcomeModalOpened(true)
      }
    })
  }, [])

  const handleCloseWelcomeModal = () => {
    setWelcomeModalOpened(false)
    navigate(URL_DASHBOARD)
  }

  const handleClosewithIcon = () => {
    setWelcomeModalOpened(false)
  }

  return (
    <Modal open={welcomeModalOpened} onClose={handleClosewithIcon}>
      <CustomModal title={''} handleClose={handleClosewithIcon}>
        <Box className={styles.modalBox}>
          <h2 className={styles.text}>{t('welcome')}</h2>
        </Box>
        <Box className={styles.modalBox2}>
          <p>{t('invitedText')}</p>
          <p className={styles.expire}>
            {t('sessionExpireson')} {timeToExpire}
          </p>
          <p className={styles.continue}>{t('continueAsGuestOrRegister')}</p>
          <a className={styles.link} href={`/register/${globalCode}`}>
            {window.location.origin}/register/{globalCode}
          </a>
        </Box>
        <Box className={styles.buttonBox}>
          <Box className={styles.button} onClick={handleCloseWelcomeModal}>
            {t('continueAsGuest')}
          </Box>
        </Box>
      </CustomModal>
    </Modal>
  )
}
