import styles from './LoggedOut.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export const LoggedOut = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.aboutus}>
      <Box className={styles.welcomeBox0}>
        <Box className={styles.welcomeBox}>
          <img
            src={require('../../assets/images/logoAbout.png')}
            width={178}
            height={178}
          />
          <Box className={styles.line} />
          <p className={styles.welcomeText}>{t('welcomeToAria')}</p>
          <p className={styles.welcomeDescription}>{t('welcomeDescription')}</p>
        </Box>
      </Box>
      <Box className={styles.needToRegister}>
        <p className={styles.text1}>{t('needToRegister')}</p>
        <Box>
          <p className={styles.text2}>{t('chooseProfile')}</p>
          <img
            src={require('../../assets/images/arrow.png')}
            height={178}
            width={178}
            className={styles.arrow}
          />
        </Box>
      </Box>
      <Box className={styles.profile}>
        <Box className={styles.researcher}>
          <Box className={styles.researcherTitle}>
            <p className={styles.title}>{t('researcher')}</p>
            <img
              src={require('../../assets/images/loupe.png')}
              className={styles.loupe}
              width={45}
              height={45}
            />
          </Box>
          <p className={styles.researcherText}>{t('researcherDescription')}</p>
        </Box>
        <Box className={styles.patient}>
          <Box className={styles.patientTitle}>
            <p className={styles.title}>{t('patient')}</p>
            <img
              src={require('../../assets/images/patient.png')}
              className={styles.loupe}
              width={45}
              height={45}
            />
          </Box>
          <p className={styles.researcherText}>{t('patientDescription')}</p>
        </Box>
      </Box>
    </section>
  )
}
