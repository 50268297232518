import { Permission } from '../../common/enums/Permissions'
import {
  URL_CHATBOT,
  URL_DASHBOARD,
  URL_DASHBOARD_RESEARCHER,
  URL_GENERAL_CHATBOT,
  URL_GUESTS,
  URL_MEDICAL_RECORD,
} from '../../routes/routes-constants'
import styles from './Navbar.module.scss'
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Link } from '@mui/material'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

interface LoggedItemsProps {
  handleClick2: (event: React.MouseEvent<HTMLElement>) => void
  selectStyle: { control: (base: any, state: { isFocused: any }) => any }
  options: { value: string; label: string }[]
  handleChangeLanguage: (value: string) => void
  lan: any
  user: LoggedUser
}

export const LoggedItems = ({
  handleClick2,
  selectStyle,
  options,
  handleChangeLanguage,
  lan,
  user,
}: LoggedItemsProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const isGuest = isRegisteredGuest || isGuestWithoutRegister

  const loggedItems = [
    {
      hidden: isGuest,
      permission: Permission.viewPanel,
      url: URL_DASHBOARD,
      text: t('dashboard'),
      className:
        location.pathname === URL_DASHBOARD
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
    {
      hidden: isGuest,
      permission: Permission.viewGeneralDashboard,
      url: URL_DASHBOARD_RESEARCHER,
      text: t('dashboard'),
      className:
        location.pathname === URL_DASHBOARD_RESEARCHER
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
    {
      hidden: isGuest,
      permission: Permission.usePatientChatbot,
      url: URL_CHATBOT,
      text: 'Chatbot',
      className:
        location.pathname === URL_CHATBOT
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
    {
      hidden: isGuest,
      permission: Permission.useGeneralChatbot,
      url: URL_GENERAL_CHATBOT,
      text: 'Chatbot',
      className:
        location.pathname === URL_GENERAL_CHATBOT
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
    {
      hidden: isGuest,
      permission: Permission.viewMedicalHistory,
      url: URL_MEDICAL_RECORD,
      text: t('myMedicalRecord'),
      className:
        location.pathname === URL_MEDICAL_RECORD
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
    {
      hidden: !isRegisteredGuest,
      permission: Permission.seeGuestSection,
      url: URL_GUESTS,
      text: t('guests'),
      className:
        location.pathname === URL_GUESTS
          ? `${styles.guestButton} ${styles.active}`
          : styles.guestButton,
    },
  ]

  return (
    <>
      {loggedItems
        .filter((item) => !item.hidden)
        .map((item) => (
          <GenerateListItem
            key={item.url}
            url={item.url}
            text={item.text}
            className={item.className ? item.className : ''}
            permission={item.permission}
          />
        ))}
      <li key="profile" className={styles.loginButton}>
        <a onClick={handleClick2}>
          <img
            src={require('../../assets/images/loginIcon.png')}
            width={25}
            style={{ alignSelf: 'center' }}
          />
        </a>
      </li>
      <li key="optionLogged">
        <Select
          options={options}
          styles={selectStyle}
          value={lan}
          onChange={(v) => handleChangeLanguage(v.value)}
        />
      </li>
    </>
  )
}

const GenerateListItem = (props: {
  permission: Permission
  url: string
  text: string
  className?: string
}) => {
  const navigate = useNavigate()

  if (!loggedUserService.userCan(props.permission)) {
    return <></>
  }
  return (
    <li key={props.url}>
      <Link
        onClick={() => navigate(props.url)}
        className={`${styles.links} ${props.className}`}
      >
        {props.text}
      </Link>
    </li>
  )
}
