import React from 'react'
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { Box } from '@mui/material'
import styles from './GenericChart.module.css'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'

interface DataPoint {
  name: string
  area: number
  bar: number
}

const data: DataPoint[] = [
  { name: 'Ene 2023', area: 500, bar: 0 },
  { name: 'Feb 2023', area: 100, bar: 20 },
  { name: 'Mar 2023', area: 1500, bar: 40 },
  { name: 'Abr 2023', area: 2000, bar: 60 },
  { name: 'May 2023', area: 2500, bar: 80 },
  { name: 'Jun 2023', area: 3000, bar: 100 },
  { name: 'Jul 2023', area: 3500, bar: 0 },
  { name: 'Ago 2023', area: 4000, bar: 20 },
]

export function GenericChart(): JSX.Element {
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  return (
    <Box
      className={
        isGuestWithoutRegister ? styles.containerGuest : styles.container
      }
    >
      <ResponsiveContainer
        width={isGuestWithoutRegister ? '80%' : '100%'}
        aspect={2}
      >
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 4000]} />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="area"
            name="Area Chart"
            fill="#01BFD5"
          />
        </AreaChart>
      </ResponsiveContainer>
      <ResponsiveContainer
        width={isGuestWithoutRegister ? '80%' : '100%'}
        aspect={2}
      >
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <Legend />
          <Bar dataKey="bar" name="Bar Chart" fill="#01BFD5" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
