import styles from './GenericHeader.module.scss'
import { Box, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type GenericHeaderProps = {
  title: string
}

export const GenericHeader: React.FC<GenericHeaderProps> = ({ title }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <h2 className={styles.title}>{t(`${title}`)}</h2>
      <Divider
        style={{
          border: '2px solid var(--GREEN-FLUOR)',
          width: '111px',
          marginTop: 4,
        }}
      />
    </Box>
  )
}
