import { Table, TableContainer, TableFooter, TableRow } from '@mui/material'
import { Head } from './Head'
import { Body } from './Body'
import { Action, Actions, Field, Pager, Search, Sort } from './types'
import React from 'react'
import { Pagination } from './Pagination'
import styles from './AppTable.module.css'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly fieldsInDraggable?: boolean
  readonly isLoading?: boolean
  readonly isPreventDefault?: boolean
  readonly handlerChecked?: (key: string) => void
  readonly checked: Record<string, boolean>
  addDeleteFile?: (file: File) => void
  isGuest?: boolean
}

export function AppTable<
  T extends Record<string, any>,
  Q extends Record<string, any>,
>(props: TableProps<T, Q>) {
  return (
    <>
      <TableContainer>
        <Table
          className={styles.responsiveTable}
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 20px',
            borderColor: 'var(--GREEN-ARIA)',
          }}
        >
          <Head
            fields={props.fields}
            sort={props.sort}
            actions={props.actions}
          />
          <Body
            actions={props.actions}
            fields={props.fields}
            items={props.items}
            rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
            handlerChecked={props.handlerChecked}
            checked={props.checked}
            addDeleteFile={props.addDeleteFile}
            isGuest={props.isGuest}
          />
        </Table>
        <Table>
          <TableFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableRow sx={{ borderBottom: 0 }}>
              {props.pager && <Pagination {...props.pager} />}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}
