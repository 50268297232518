import { Box, Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import styles from './UploadFile.module.scss'
import { useTranslation } from 'react-i18next'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileDTO } from '../../modules/files/models/FileDTO'
import { getAcitivtyContainer } from '../../container/activity-module'
import { ActivityService } from '../../modules/activity/services/ActivityService'
import { ACTIVITY_SERVICE_KEY } from '../../modules/activity'
import { ActivityDTO } from '../../modules/activity/models/ActivityDTO'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { ActivityType } from '../../modules/activity/enums/ActivityEnum'
import { v4 as uuidv4 } from 'uuid'
import { useSnackbar } from 'notistack'
import { styleTextField } from '../../common/styles/TextFieldStyle'
import { UserCard } from '../../components/user-card/UserCard'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import useIsDocFile from '../../hooks/useIsDocFile'
import { DocType, FileType } from '../../modules/files/enum/FileEnums'

type UploadFileProps = {
  file: FileDTO | null
  handlePreviewClose: () => void
  loggedUser: LoggedUser | undefined
  urlFile: string
  id?: string
  openPreviewGuest?: boolean
}

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const activityService =
  getAcitivtyContainer().get<ActivityService>(ACTIVITY_SERVICE_KEY)
export const UploadFile = ({
  file,
  handlePreviewClose,
  loggedUser,
  urlFile,
  id,
  openPreviewGuest,
}: UploadFileProps) => {
  const { t } = useTranslation()
  const [image, setImage] = useState<string | null>(null)
  const [nameOfFile, setNameOfFile] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fileEdit, setFileEdit] = useState<FileDTO | null>(null)
  const [urlEdit, setUrlEdit] = useState<string>('')
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const isGuest = isRegisteredGuest || isGuestWithoutRegister
  const isDocFile = useIsDocFile(file, fileEdit)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (id) {
      if (fileEdit?.type === FileType.Png || fileEdit?.type === FileType.Jpg) {
        setImage(urlEdit)
      }
      return
    }
    if (file?.type === FileType.Png || file?.type === FileType.Jpg) {
      setImage(urlFile)
    }
  }, [file, fileEdit, urlEdit])

  useEffect(() => {
    if (!id) return
    fileService.getByID(id).subscribe((res) => {
      if (!res) return
      setFileEdit(res)
      setNameOfFile(res.name)
      if (urlEdit) {
        URL.revokeObjectURL(urlEdit)
      }
      const binaryData = atob(res.base64)
      const arrayBuffer = new ArrayBuffer(binaryData.length)
      const uint8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
      }
      const blob = new Blob([arrayBuffer], { type: FileType.PDF })
      const pdfUrl = URL.createObjectURL(blob)
      setUrlEdit(pdfUrl)
    })
  }, [id])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameOfFile(e.target.value)
  }

  const handleSave = () => {
    if (nameOfFile === '') {
      enqueueSnackbar(t('fileNameEmpty'), { variant: 'warning' })
      return
    }

    if (file?.docType === DocType.Image) {
      if (!id) {
        if (file === null) return
        file.name = nameOfFile
        fileService.addImage(file).subscribe((res) => {
          if (!res) {
            enqueueSnackbar(t('anErrorHasOccurred'), { variant: 'error' })
            handlePreviewClose()
            return
          }
          const activity: ActivityDTO = {
            id: uuidv4(),
            date: new Date(),
            userID: loggedUser?.id ?? '',
            type: ActivityType.FileUpload,
            userName: '',
          }

          activityService.add(activity).subscribe(() => {
            setIsLoading(false)
            if (res) {
              enqueueSnackbar(t('fileCorrectlyUpload'), { variant: 'success' })
            }
            handlePreviewClose()
          })
        })
      } else {
        if (!fileEdit) return
        fileEdit.name = nameOfFile
        fileService.update(fileEdit).subscribe((res) => {
          const activity: ActivityDTO = {
            id: uuidv4(),
            date: new Date(),
            userID: loggedUser?.id ?? '',
            type: ActivityType.FileUpdate,
            userName: '',
          }

          activityService.add(activity).subscribe(() => {
            if (res) {
              enqueueSnackbar(t('fileCorrectlyUpdated'), { variant: 'success' })
            } else {
              enqueueSnackbar(t('anErrorHasOccurred'), { variant: 'error' })
            }
            handlePreviewClose()
          })
        })
      }
    } else {
      if (!id) {
        if (file === null) return
        file.name = nameOfFile
        fileService.addFile(file).subscribe((res) => {
          const activity: ActivityDTO = {
            id: uuidv4(),
            date: new Date(),
            userID: loggedUser?.id ?? '',
            type: ActivityType.FileUpload,
            userName: '',
          }

          activityService.add(activity).subscribe(() => {
            setIsLoading(false)
            if (res) {
              enqueueSnackbar(t('fileCorrectlyUpload'), { variant: 'success' })
            } else {
              enqueueSnackbar(t('anErrorHasOccurred'), { variant: 'error' })
            }
            handlePreviewClose()
          })
        })
      } else {
        if (!fileEdit) return
        fileEdit.name = nameOfFile
        fileService.update(fileEdit).subscribe((res) => {
          const activity: ActivityDTO = {
            id: uuidv4(),
            date: new Date(),
            userID: loggedUser?.id ?? '',
            type: ActivityType.FileUpdate,
            userName: '',
          }

          activityService.add(activity).subscribe(() => {
            if (res) {
              enqueueSnackbar(t('fileCorrectlyUpdated'), { variant: 'success' })
            } else {
              enqueueSnackbar(t('anErrorHasOccurred'), { variant: 'error' })
            }
            handlePreviewClose()
          })
        })
      }
    }
  }

  const handlePressKey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSave()
    }
  }

  return (
    <Box className={isGuest ? styles.contentGuest : styles.container}>
      {isGuest && (
        <Box>
          <UserCard />
        </Box>
      )}
      <Box
        className={styles.boxPreview}
        mt={'29px'}
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
      >
        <Box className={styles.content}>
          <TextField
            sx={styleTextField}
            InputProps={{
              style: { fontFamily: 'Roboto-Light', borderRadius: 24 },
            }}
            style={
              window.innerWidth < 378
                ? { width: '120px', fontFamily: 'Roboto-Light' }
                : window.innerWidth < 490
                ? { width: '150px', fontFamily: 'Roboto-Light' }
                : { width: '220px', fontFamily: 'Roboto-Light' }
            }
            onChange={handleChange}
            variant="outlined"
            size="small"
            type={'text'}
            value={nameOfFile}
            placeholder={t('fileName')}
            onKeyDown={handlePressKey}
            disabled={openPreviewGuest}
          />
          <Box>
            <Button
              onClick={() => {
                setImage('')
                handlePreviewClose()
              }}
              className={styles.button + ' ' + styles.buttonCancel}
            >
              <span className={styles.text}>
                {openPreviewGuest ? t('back') : t('cancel')}
              </span>
            </Button>
            <Button
              onClick={handleSave}
              className={styles.button + ' ' + styles.buttonGreen}
              sx={{ display: openPreviewGuest ? 'none' : '' }}
            >
              <span className={styles.text}>{t('uploadFile')}</span>
            </Button>
          </Box>
        </Box>
        <Box className={styles.preview}>
          {image ? (
            <Box className={styles.imagePreview}>
              <img src={image} alt="preview" width={'100%'} />
            </Box>
          ) : (
            <>
              {isDocFile ? (
                <Box>
                  <h2 className={styles.notViewDocument}>
                    {t('docxFile')} {t('notViewDocument')}
                  </h2>
                </Box>
              ) : (
                <embed
                  src={`${urlEdit || urlFile}#toolbar=0&navpanes=0&scrollbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
