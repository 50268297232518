import { getAcitivtyContainer } from '../../container/activity-module'
import { ACTIVITY_SERVICE_KEY } from '../../modules/activity'
import { type ActivityService } from '../../modules/activity/services/ActivityService'
import styles from './ActivityHistory.module.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Query } from '../../common/api/Query'
import { getUserContainer } from '../../container/user-module'
import { type LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { type Activity } from '../../modules/activity/models/Activity'
import { activityTypes } from '../../modules/activity/enums/ActivityEnum'
import Select from 'react-select'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box } from '@mui/material'

const activityService =
  getAcitivtyContainer().get<ActivityService>(ACTIVITY_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const ActivityHistory = () => {
  const { t } = useTranslation()
  const [items, setItems] = useState<Activity[]>([])
  const [page, setPage] = useState<number>(0)
  const [resultsByPage, setResultsByPage] = useState<number>(8)
  const [total, setTotal] = useState<number>(0)
  const userId = loggedUserService.get()?.id
  const options = [
    { value: 8, label: 8 },
    { value: 16, label: 16 },
    { value: 24, label: 24 },
  ]

  useEffect(() => {
    if (userId == null) return
    activityService.getAllByUserID(userId).subscribe((res) => {
      setTotal(res?.items?.length)
    })
  }, [])

  useEffect(() => {
    if (userId == null) return
    activityService
      .getByUserID(
        userId,
        new Query({
          pager: { offset: page * resultsByPage, limit: resultsByPage },
        }),
      )
      .subscribe((res) => {
        setItems(res.items)
      })
  }, [page, resultsByPage, total])

  const selectStyle = {
    control: (base: any, state: { isFocused: boolean }) => ({
      ...base,
      border: state.isFocused
        ? 'solid 2px var(--GREEN-ARIA)'
        : 'solid 1px var(--GREEN-ARIA)',
      '&:hover': {
        border: 'solid 2px #00E6A1',
      },
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #00E6A1' : 'none',
    }),
    option: (
      base: any,
      state: {
        data: any
        isDisabled: boolean
        isFocused: boolean
        isSelected: boolean
      },
    ) => ({
      ...base,
      backgroundColor: state.isFocused ? '#00E6A1' : 'white',
      color: state.isFocused ? 'white' : 'var(--TEXT-GRAY-DARK)',
      ':active': {
        ...base[':active'],
        border: 'solid 1px #00E6A1',
      },
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#00E6A1',
      ':hover': {
        color: 'rgba(0,230,161,0.63)',
      },
    }),
  }

  const handleNextPage = () => {
    total > page * resultsByPage + resultsByPage && setPage(page + 1)
  }

  const handleBackPage = () => {
    page > 0 && setPage(page - 1)
  }

  return (
    <Box className={styles.ahContainer}>
      <Box className={styles.ahContainer2}>
        <p className={styles.activityTitle}>{t('activityHistory')}</p>
        <Box>
          <Box className={styles.linedashboard} />
          <Box className={styles.tableHeader}>
            <Box className={styles.tableHeader2}>
              <p className={styles.date0}>{t('date')}</p>
              <p className={styles.name0}>{t('name')}</p>
              <p className={styles.activity0}>{t('activity')}</p>
            </Box>
          </Box>
          <Box className={styles.table}>
            {items?.map((item, i) => (
              <Box
                key={item.id}
                className={styles.row}
                style={{ marginTop: i === 0 ? '2%' : '0%' }}
              >
                <p className={styles.date}>
                  {new Date(item.date).toLocaleDateString()}
                </p>
                <p className={styles.name}>{item.userName}</p>
                <p className={styles.activity}>{activityTypes()[item.type]}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={styles.paginations}>
          <p className={styles.results}>{t('resultsByPage')}</p>
          <Select
            styles={selectStyle}
            options={options}
            value={{ value: resultsByPage, label: resultsByPage }}
            onChange={(v) => {
              v != null && setResultsByPage(v.value)
            }}
            menuPlacement="top"
          />
          <Box
            style={{
              alignSelf: 'center',
              marginLeft: '3%',
              cursor: page > 0 ? 'pointer' : '',
            }}
            onClick={handleBackPage}
          >
            <ArrowBackIosIcon
              style={{ color: page > 0 ? '#00E6A1' : '#d4d4d4', width: 20 }}
            />
          </Box>
          <Box
            style={{
              alignSelf: 'center',
              marginLeft: '0.5%',
              cursor:
                total > page * resultsByPage + resultsByPage ? 'pointer' : '',
            }}
            onClick={handleNextPage}
          >
            <ArrowForwardIosIcon
              style={{
                color:
                  total > page * resultsByPage + resultsByPage
                    ? '#00E6A1'
                    : '#d4d4d4',
                width: 20,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
