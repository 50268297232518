import React, { createContext, useContext, useState } from 'react'
import { UserDTO } from '../../modules/users/models/User'

interface UserProviderProps {
  children: React.ReactNode
}

interface UserContextType {
  selectedUser: UserDTO | undefined
  setSelectedUser: React.Dispatch<React.SetStateAction<UserDTO | undefined>>
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const storedUserString = localStorage.getItem('selectedUser')
  const [selectedUser, setSelectedUser] = useState<UserDTO | undefined>(
    storedUserString ? JSON.parse(storedUserString) : undefined,
  )

  return (
    <UserContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </UserContext.Provider>
  )
}
