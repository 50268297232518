import styles from './AboutUs.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export const AboutUS = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <section className={styles.about}>
      <Box className={styles.firstContainer}>
        <Box className={styles.aboususBox}>
          <p className={styles.aboutUSText}>{t('aboutUS')}</p>
          <Box className={styles.lineabout} />
          <p className={styles.aboutUSDescription}>{t('aboutusDescription')}</p>
        </Box>
        <Box className={styles.profilecontainer}>
          <Box
            className={styles.dashboard}
            style={{ backgroundColor: 'var(--BLUE-ARIA)' }}
          >
            <Box
              className={styles.box}
              style={{ backgroundColor: 'var(--BLUE-ARIA)' }}
            >
              <img
                src={require('../../assets/images/dashboardicon.png')}
                width={86}
                height={86}
              />
            </Box>
            <p>{t('dashboard')}</p>
          </Box>
          <Box
            className={styles.chatbotbox}
            style={{
              backgroundColor: 'var(--GREEN-FLUOR)',
              borderRadius: '11px',
              marginLeft: '3%',
            }}
          >
            <Box className={styles.box}>
              <img
                src={require('../../assets/images/chatbotpanel.png')}
                width={140}
                height={110}
              />
            </Box>
            <p>Chatbot</p>
          </Box>
        </Box>
      </Box>
      <Box className={styles.partnersBox}>
        <Box className={styles.partnerItem}>
          <img
            className={styles.img}
            src={require('../../assets/images/partner1.png')}
          />
        </Box>
        <Box className={styles.line} />
        <Box className={styles.partnerItem}>
          <img src={require('../../assets/images/partner2.png')} />
        </Box>
        <Box className={styles.line} />
        <Box className={styles.partnerItem}>
          <img src={require('../../assets/images/partner5.png')} />
        </Box>
        <Box className={styles.line} />
        <Box className={styles.partnerItem}>
          <img src={require('../../assets/images/partner3.png')} />
        </Box>
        <Box className={styles.line} />
        <Box className={styles.partnerItem}>
          <img src={require('../../assets/images/partner4.png')} />
        </Box>
      </Box>
    </section>
  )
}
