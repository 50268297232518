import { Box } from '@mui/material'
import styles from './PhoneCodeSelector.module.scss'
import { PatternFormat } from 'react-number-format'
import { PhoneData } from './PhoneData'
import { useTranslation } from 'react-i18next'
import React from 'react'

interface PhoneNumberInputProps {
  phoneNumber: string
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  selectedCountry: string
}
const formatByCountryCodeLength: { [key: number]: string; fallback: string } = {
  2: '+# ### ### ###',
  3: '+## ### ### ###',
  4: '+### ### ### ###',
  fallback: '+## ### ### ###',
}
export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  phoneNumber,
  handlePhoneNumberChange,
  handleOnKeyDown,
  selectedCountry,
}) => {
  const { t } = useTranslation()

  const getPhoneNumberFormat = (selectedCountry: string) => {
    const countryCodeLength = PhoneData[selectedCountry].length
    return (
      formatByCountryCodeLength[countryCodeLength] ||
      formatByCountryCodeLength.fallback
    )
  }
  return (
    <Box className={styles.containerTextField}>
      <PatternFormat
        className={styles.inputInvite}
        value={phoneNumber}
        placeholder={t('phone')}
        format={getPhoneNumberFormat(selectedCountry)}
        onChange={handlePhoneNumberChange}
        onKeyDown={handleOnKeyDown}
      />
    </Box>
  )
}
