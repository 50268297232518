import styles from './PrivacyCookies.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export const PrivacyCookies = () => {
  const { t } = useTranslation()

  return (
    <Box className={styles.privacyContainer}>
      <Box className={styles.privacyContainer2}>
        <p className={styles.privacyTitle}>{t('privacyCookies')}</p>
        <Box className={styles.linedashboard0}>
          <Box className={styles.linedashboard} />
        </Box>
        <Box className={styles.textbox}>
          <p className={styles.title}>{t('termsOfUse')}</p>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            dolor nulla, semper vitae malesuada ac, luctus in turpis. Proin in
            consequat urna, nec pulvinar lectus. Etiam vulputate nulla nec
            rutrum molestie…{' '}
          </p>
          <p className={styles.title}>{t('privacyPolicy')}</p>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            dolor nulla, semper vitae malesuada ac, luctus in turpis. Proin in
            consequat urna, nec pulvinar lectus. Etiam vulputate nulla nec
            rutrum molestie…{' '}
          </p>
          <p className={styles.title}>{t('cookiesPolicy')}</p>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            dolor nulla, semper vitae malesuada ac, luctus in turpis. Proin in
            consequat urna, nec pulvinar lectus. Etiam vulputate nulla nec
            rutrum molestie…{' '}
          </p>
        </Box>
      </Box>
    </Box>
  )
}
