import styles from './DashboardResearcher.module.scss'
import React from 'react'
import { Box } from '@mui/material'
import { GenericChart } from '../../components/generic-chart/GenericChart'
import { UserCard } from '../../components/user-card/UserCard'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import { GenericHeader } from '../../components/header/GenericHeader'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'

export const DashboardResearcher = () => {
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const { isRegisteredGuest } = useIsRegisteredGuest()

  const isGuest = isRegisteredGuest || isGuestWithoutRegister

  return (
    <Box className={styles.container}>
      <GenericHeader title={'general'} />
      <Box className={styles.dashboardContainer}>
        <Box className={styles.content}>
          {isGuest && (
            <Box>
              <UserCard />
            </Box>
          )}
          <Box className={styles.chartContainer}>
            <GenericChart />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
