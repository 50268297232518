import styles from './Navbar.module.scss'
import { useState } from 'react'
import { URL_LOGGEDOUT, URL_PROFILE } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import i18next from '../../i18n/i18n'
import i18n from '../../i18n/i18n'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { type LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getUserContainer } from '../../container/user-module'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import { menuNavStyles } from '../../common/styles/MenuStyles'
import { GeneralItems } from './GeneralItems'
import { LoggedItems } from './LoggedItems'
import { Permission } from '../../common/enums/Permissions'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'
import { useUser } from '../../common/utils/GlobalSelectedUser'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

const Navbar = () => {
  const options = [
    { value: 'en', label: 'EN' },
    { value: 'es', label: 'ES' },
    { value: 'fr', label: 'FR' },
    { value: 'ger', label: 'GER' },
  ]
  const navigate = useNavigate()
  const { user } = useLoggedInUser()
  const [clicked, setClicked] = useState<boolean>(false)
  const [lan, setLan] = useState<any>({
    value: i18next.language,
    label: i18next.language.toUpperCase(),
  })
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const { setSelectedUser } = useUser()

  const selectStyle = {
    control: (base: any, state: { isFocused: boolean }) => ({
      ...base,
      border: 0,
      boxShadow: 0,
      '&:hover': {
        border: 0,
      },
    }),
  }

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = () => {
    setClicked(!clicked)
  }

  const handleChangeLanguage = (v: string) => {
    setLan({ value: v, label: v.toUpperCase() })
    i18n.changeLanguage(v)
  }

  const handleLogout = () => {
    loggedUserService.logout()
    navigate(URL_LOGGEDOUT)
    setSelectedUser(undefined)
  }

  return (
    <nav className={styles.nav}>
      <a
        onClick={() => {
          navigate(URL_LOGGEDOUT)
        }}
        className={styles.logoContainer}
      >
        <table>
          <tbody>
            <tr className={styles.logoBox}>
              <td>
                <img src={require('../../assets/images/logo.png')} width={44} />
              </td>
              <td className={styles.textBox}>
                <p className={styles.logoText}>ARIA</p>
              </td>
            </tr>
          </tbody>
        </table>
      </a>
      <Box>
        <ul
          id="navbar"
          className={
            clicked ? `${styles.navbar} ${styles.active} ` : styles.navbar
          }
        >
          {user ? (
            <LoggedItems
              handleChangeLanguage={handleChangeLanguage}
              handleClick2={handleClick2}
              lan={lan}
              options={options}
              selectStyle={selectStyle}
              user={user}
            />
          ) : (
            <GeneralItems
              handleChangeLanguage={handleChangeLanguage}
              lan={lan}
              options={options}
              selectStyle={selectStyle}
            />
          )}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={menuNavStyles}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                navigate(URL_PROFILE)
              }}
              style={{
                display:
                  user?.permissions.includes(Permission.seeGuestSection) &&
                  !isRegisteredGuest
                    ? 'none'
                    : 'visible',
              }}
            >
              <Avatar /> {t('profile')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t('logout')}
            </MenuItem>
          </Menu>
        </ul>
      </Box>
      <Box id="mobile" className={styles.mobile} onClick={handleClick}>
        <i
          className={clicked ? 'fas fa-xmark' : 'fas fa-bars'}
          style={{ color: '#17cf97' }}
        ></i>
      </Box>
    </nav>
  )
}

export default Navbar
