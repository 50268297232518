import styles from './Guest.module.scss'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { UserService } from '../../modules/users/services/UserService'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Box, Modal } from '@mui/material'
import ReactCodeInput from 'react-code-input'
import { useSnackbar } from 'notistack'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'
import { URL_CHATBOT } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { CustomModal } from '../../components/modal/CustomModal'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const Guest = () => {
  const { t } = useTranslation()
  const [code, setCode] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const [timeToExpire, setTimeToExpire] = useState<string>('')
  const [welcomeModalOpened, setWelcomeModalOpened] = useState<boolean>(false)
  const { setGoodbyeModal, setGlobalCode } = useGlobalContext()
  const navigate = useNavigate()

  const handleCloseWelcomeModal = () => {
    setWelcomeModalOpened(false)
    navigate(URL_CHATBOT)
  }

  const handleSend = () => {
    if (code?.length < 6) {
      return
    }

    if (loggedUserService.get()) {
      // logueado
      userService
        .addInvitationCode(code, loggedUserService.get()?.id ?? '')
        .subscribe((res) => {
          setCode('')
          if (res) {
            enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'success' })
          } else {
            enqueueSnackbar(t('codeCorrectlyAdded'), { variant: 'success' })
          }
        })
    } else {
      setGlobalCode(code)
      loggedUserService.loginGuest(code).subscribe((res) => {
        if (!res) {
          enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
        } else {
          const differenceInMilliseconds =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            new Date(res.sessionExpires) - new Date()
          const minutes = differenceInMilliseconds / (1000 * 60)

          setTimeToExpire(minutes.toFixed(0) + ' ' + t('minutes'))
          setTimeout(() => {
            setGoodbyeModal(true)
            loggedUserService.logout()
          }, differenceInMilliseconds)

          setWelcomeModalOpened(true)
        }
      })
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.guestC}>
        <Box className={styles.guestTitle}>
          <p className={styles.guestTitleText}>{t('guestSection')}</p>
          <Box className={styles.linedashboard0}>
            <Box className={styles.linedashboardGuest} />
          </Box>
          <img
            src={require('../../assets/images/arrow.png')}
            width={130}
            height={130}
            className={styles.arrow}
          />
        </Box>
        <Box className={styles.guestBox}>
          <Box className={styles.guestBox2}>
            <img
              src={require('../../assets/images/guesticon.png')}
              className={styles.guesticon}
            />
            <p className={styles.guestSectionDescription}>
              {t('guestSectionDescription')}
            </p>
            <p className={styles.guestSectionDescription2}>
              {t('guestSectionDescription2')}
            </p>
            <ReactCodeInput
              isValid={true}
              fields={6}
              onChange={(v) => setCode(v)}
              value={code}
              name={''}
              inputMode={'email'}
            />
            <Box onClick={handleSend} className={styles.registerButtonGuest}>
              <p>{t('send')}</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={welcomeModalOpened} onClose={handleCloseWelcomeModal}>
        <CustomModal title={''} handleClose={handleCloseWelcomeModal}>
          <Box className={styles.modalBox}>
            <h2 className={styles.text}>{t('welcome')}</h2>
          </Box>
          <Box className={styles.modalBox2}>
            <p>{t('invitedText')}</p>
            <p className={styles.expire}>
              {t('sessionExpireson')} {timeToExpire}
            </p>
            <p className={styles.continue}>{t('continueAsGuestOrRegister')}</p>
            <a className={styles.link} href={`/register/${code}`}>
              {window.location.origin}/register/{code}
            </a>
          </Box>
          <Box className={styles.buttonBox}>
            <Box className={styles.button} onClick={handleCloseWelcomeModal}>
              {t('continueAsGuest')}
            </Box>
          </Box>
        </CustomModal>
      </Modal>
    </Box>
  )
}
