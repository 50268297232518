import styles from './Chatbot.module.scss'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import { Permission } from '../../common/enums/Permissions'
import { UserCard } from '../../components/user-card/UserCard'
import { GenericHeader } from '../../components/header/GenericHeader'

type ChatbotProps = {
  type: string
}

type Message = {
  from: string
  msg: string
}

export const Chatbot = (props: ChatbotProps) => {
  const { t } = useTranslation()
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const scrollableDivRef = useRef(null)
  const { user } = useLoggedInUser()

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    const aux: Message[] = [
      { from: 'me', msg: '¿Qué sabes sobre mi enfermedad?' },
      {
        from: 'chat',
        msg: 'Todavía no tengo esa información, por favor actualiza tu historial médico',
      },
      { from: 'me', msg: 'vale, ya está' },
      { from: 'chat', msg: 'genial' },
      { from: 'me', msg: '¿Qué sabes sobre mi enfermedad?' },
      {
        from: 'chat',
        msg: 'Todavía no tengo esa información, por favor actualiza tu historial médico',
      },
      { from: 'me', msg: 'vale, ya está' },
      { from: 'chat', msg: 'genial' },
    ]

    setMessages(aux)
  }, [])

  const sendMessage = () => {
    const auxMessages = [...messages]
    auxMessages.push({
      msg: message,
      from: 'me',
    })
    setMessages(auxMessages)
    setMessage('')
  }

  const handlePressKey = (event: {
    key: string
    preventDefault: () => void
  }) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  return (
    <Box className={styles.container}>
      <GenericHeader title={'Chatbot'} />
      <Box className={styles.chatcontainer}>
        <Box className={styles.chatchatbot}>
          <Box className={styles.contentBox}>
            {user?.permissions.includes(Permission.seeGuestSection) && (
              <Box>
                <UserCard />
              </Box>
            )}
            <Box className={styles.chatbox}>
              <Box className={styles.boxScroll} ref={scrollableDivRef}>
                {messages.map((item, i) => (
                  <Box key={item.msg + i}>
                    {item.from === 'chat' ? (
                      <Box className={styles.patientBox}>
                        <img
                          src={require('../../assets/images/chaticon.png')}
                          width={41}
                          height={41}
                          className={styles.chaticon}
                        />
                        <Box className={styles.patientBox2}>
                          <img
                            src={require('../../assets/images/rectanglechatbot.png')}
                            width={18}
                            height={18}
                          />
                          <Box className={styles.patientboxMsg}>{item.msg}</Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className={styles.patientBoxRight}>
                        <Box
                          style={{
                            width: window.innerWidth > 1490 ? '70px' : '121px',
                          }}
                        />
                        <Box className={styles.patientBoxRight2}>
                          <Box className={styles.patientboxMsgRight}>
                            {item.msg}
                          </Box>
                          <img
                            src={require('../../assets/images/rectangleme.png')}
                            width={18}
                            height={18}
                          />
                          <img
                            src={require('../../assets/images/chatmeicon.png')}
                            className={styles.chatmeicon}
                            width={41}
                            height={41}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box className={styles.boxWrite}>
                <input
                  id="chatinput"
                  onKeyDown={handlePressKey}
                  type="text"
                  className={styles.input}
                  placeholder={t('typeAmessage')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <img
                  className={styles.img}
                  onClick={sendMessage}
                  src={require('../../assets/images/arrowchat.png')}
                  width={23}
                  height={23}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
