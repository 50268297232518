import { createContext, useContext } from 'react'

export interface GlobalContent {
  goodbyeModal: boolean
  setGoodbyeModal: (c: boolean) => void
  globalCode: string
  setGlobalCode: (c: string) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
  goodbyeModal: false,
  setGoodbyeModal: () => {},
  globalCode: '',
  setGlobalCode: () => {},
})

export const useGlobalContext = () => useContext(MyGlobalContext)
