import { Container } from '../../common/container/Container'

export type InvitationContainerConfig = {
  moduleFullUrl: string
}

export type InvitationProps = {
  parentContainer: Container
  config: InvitationContainerConfig
}

export const INVITATION_MODULE = Symbol('INVITATION_MODULE')

export const INVITATION_API_KEY = Symbol('INVITATION_API_KEY')

export const INVITATION_SERVICE_KEY = Symbol('INVITATION_SERVICE_KEY')
