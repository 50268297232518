import { Query } from './Query'

export async function getResponseError(res: Response): Promise<Error> {
  const err = new Error(
    `${httpStatuses()[res.status as HTTPStatus]} ${await res.text()}`,
  )
  err.name = httpStatuses()[res.status as HTTPStatus]
  return err
}

export function prepareURL<T>(
  resourceURL: string,
  params: Query<T> | Record<string, string> | undefined = undefined,
): string {
  if (!params) {
    return resourceURL
  }
  if (params instanceof Query) {
    return `${resourceURL}?${params.buildQuery()}`
  } else {
    let url = resourceURL
    url += '?'
    Object.keys(params).forEach((k) => {
      if (params[k] !== undefined && params[k] !== null) {
        url += k + '=' + params[k] + '&'
      }
    })
    url = url.substring(0, url.length - 1)
    return encodeURI(url)
  }
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status HTTP response status codes}
 */
export enum HTTPStatus {
  Continue = 100,
  SwitchingProtocol = 101,
  EarlyHints = 103,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultipleChoices = 300,
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  NotModified = 304,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  PayloadTooLarge = 413,
  URITooLong = 414,
  UnsupportedMediaType = 415,
  RangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  IAmATeapot = 418,
  UnprocessableEntity = 422,
  TooEarly = 425,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HTTPVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511,
}

export const httpStatuses = (): Record<HTTPStatus, string> => ({
  [HTTPStatus.Continue]: 'Continue',
  [HTTPStatus.SwitchingProtocol]: 'Switching Protocol',
  [HTTPStatus.EarlyHints]: 'Early Hints',
  [HTTPStatus.OK]: 'OK',
  [HTTPStatus.Created]: 'Created',
  [HTTPStatus.Accepted]: 'Accepted',
  [HTTPStatus.NonAuthoritativeInformation]: 'Non Authoritative Information',
  [HTTPStatus.NoContent]: 'No Content',
  [HTTPStatus.ResetContent]: 'Reset Content',
  [HTTPStatus.PartialContent]: 'Partial Content',
  [HTTPStatus.MultipleChoices]: 'Multiple Choices',
  [HTTPStatus.MovedPermanently]: 'Moved Permanently',
  [HTTPStatus.Found]: 'Found',
  [HTTPStatus.SeeOther]: 'See Other',
  [HTTPStatus.NotModified]: 'Not Modified',
  [HTTPStatus.TemporaryRedirect]: 'Temporary Redirect',
  [HTTPStatus.PermanentRedirect]: 'Permanent Redirect',
  [HTTPStatus.BadRequest]: 'Bad Request',
  [HTTPStatus.Unauthorized]: 'Unauthorized',
  [HTTPStatus.PaymentRequired]: 'Payment Required',
  [HTTPStatus.Forbidden]: 'Forbidden',
  [HTTPStatus.NotFound]: 'Not Found',
  [HTTPStatus.MethodNotAllowed]: 'Method Not Allowed',
  [HTTPStatus.NotAcceptable]: 'Not Acceptable',
  [HTTPStatus.ProxyAuthenticationRequired]: 'Proxy Authentication Required',
  [HTTPStatus.RequestTimeout]: 'Request Timeout',
  [HTTPStatus.Conflict]: 'Conflict',
  [HTTPStatus.Gone]: 'Gone',
  [HTTPStatus.LengthRequired]: 'Length Required',
  [HTTPStatus.PreconditionFailed]: 'Precondition Failed',
  [HTTPStatus.PayloadTooLarge]: 'Payload Too Large',
  [HTTPStatus.URITooLong]: 'URI Too Long',
  [HTTPStatus.UnsupportedMediaType]: 'Unsupported Media Type',
  [HTTPStatus.RangeNotSatisfiable]: 'Range Not Satisfiable',
  [HTTPStatus.ExpectationFailed]: 'Expectation Failed',
  [HTTPStatus.IAmATeapot]: "I'm a teapot",
  [HTTPStatus.UnprocessableEntity]: 'Unprocessable Entity',
  [HTTPStatus.TooEarly]: 'Too Early',
  [HTTPStatus.UpgradeRequired]: 'Upgrade Required',
  [HTTPStatus.PreconditionRequired]: 'Precondition Required',
  [HTTPStatus.TooManyRequests]: 'Too Many Requests',
  [HTTPStatus.RequestHeaderFieldsTooLarge]: 'Request Header Fields Too Large',
  [HTTPStatus.UnavailableForLegalReasons]: 'Unavailable For Legal Reasons',
  [HTTPStatus.InternalServerError]: 'Internal Server Error',
  [HTTPStatus.NotImplemented]: 'Not Implemented',
  [HTTPStatus.BadGateway]: 'Bad Gateway',
  [HTTPStatus.ServiceUnavailable]: 'Service Unavailable',
  [HTTPStatus.GatewayTimeout]: 'Gateway Timeout',
  [HTTPStatus.HTTPVersionNotSupported]: 'HTTP Version Not Supported',
  [HTTPStatus.VariantAlsoNegotiates]: 'Variant Also Negotiates',
  [HTTPStatus.InsufficientStorage]: 'Insufficient Storage',
  [HTTPStatus.LoopDetected]: 'Loop Detected',
  [HTTPStatus.NotExtended]: 'Not Extended',
  [HTTPStatus.NetworkAuthenticationRequired]: 'Network Authentication Required',
})
