import { Box, Button, Divider } from '@mui/material'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import editIcon from '../../assets/table_icons/edit.svg'
import deleteIcon from '../../assets/table_icons/delete.svg'
import uploadIcon from '../../assets/table_icons/upload_file.svg'

import styles from './ActionsComponent.module.scss'
import { Searcher } from './Searcher'
import { Search } from './types'
import { Permission } from '../../common/enums/Permissions'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'

type ActionsProps<Q> = {
  readonly search: Search<Q>
  searchComponent?: boolean
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOpenEdit?: () => void
  handleDelete: () => void
  handleOpenModalRegister?: () => void
  disabled?: boolean
}

export function ActionsComponent<Q extends Record<string, any>>({
  searchComponent,
  handleFileSelect,
  handleDelete,
  disabled,
  handleOpenEdit,
  search,
  handleOpenModalRegister,
}: ActionsProps<Q>) {
  const { t } = useTranslation()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { user } = useLoggedInUser()
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const { isRegisteredGuest } = useIsRegisteredGuest()

  const isGuest = isRegisteredGuest || isGuestWithoutRegister

  return (
    <>
      {isGuest ? (
        <Box className={styles.actionGuestSection}>
          <Searcher search={search} />
          <Button
            disabled={disabled}
            onClick={() => {
              if (isGuestWithoutRegister) {
                handleOpenModalRegister && handleOpenModalRegister()
                return
              }
              fileInputRef.current?.click()
            }}
            className={styles.button + ' ' + styles.buttonGreen}
          >
            <img className={styles.icon} src={uploadIcon} alt={'upload'} />
            <span className={styles.text}>{t('uploadFile')}</span>
            <input
              disabled={isGuestWithoutRegister}
              style={{ display: 'none' }}
              ref={fileInputRef}
              type="file"
              onChange={handleFileSelect}
            />
          </Button>
        </Box>
      ) : (
        <Box className={styles.actionsContainer}>
          <h4 className={styles.actionsTitle}>{t('actions')}</h4>
          <Divider className={styles.divider} />
          <Box className={styles.content}>
            {user?.permissions.includes(Permission.editMedicalHistory) && (
              <Button
                onClick={handleOpenEdit}
                disabled={disabled}
                className={styles.button + ' ' + styles.buttonGreen}
              >
                <img className={styles.icon} src={editIcon} alt={'edit'} />
                <span className={styles.text}>{t('edit')}</span>
              </Button>
            )}

            {user?.permissions.includes(Permission.deleteMedicalHistory) && (
              <Button
                className={styles.button + ' ' + styles.buttonRed}
                onClick={handleDelete}
              >
                <img className={styles.icon} src={deleteIcon} alt={'delete'} />
                <span className={styles.text}>{t('delete')}</span>
              </Button>
            )}

            {user?.permissions.includes(
              Permission.uploadFileMedicalHistory,
            ) && (
              <Button
                disabled={disabled}
                onClick={() => fileInputRef.current?.click()}
                className={styles.button + ' ' + styles.buttonGreen}
              >
                <img className={styles.icon} src={uploadIcon} alt={'upload'} />
                <span className={styles.text}>{t('uploadFile')}</span>
                <input
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileSelect}
                />
              </Button>
            )}
          </Box>
          <Divider className={styles.dividerBottom} />
          {searchComponent && <Searcher search={search} />}
        </Box>
      )}
    </>
  )
}
