import styles from './Support.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export const Support = () => {
  const { t } = useTranslation()

  return (
    <Box className={styles.supportContainer}>
      <Box className={styles.supportContainer2}>
        <p className={styles.supportTitle}>{t('support')}</p>
        <Box className={styles.linedashboard0}>
          <Box className={styles.linedashboard} />
          <Box className={styles.boxes}>
            <Box className={styles.itemboxAbout} style={{ marginLeft: '0%' }}>
              <img
                src={require('../../assets/images/ariasupport.png')}
                width={140}
                height={110}
              />
              <p style={{ marginTop: '8%' }}>{t('aboutAria')}</p>
            </Box>
            <Box
              className={styles.itembox2}
              style={{ backgroundColor: 'var(--GREEN-FLUOR)' }}
            >
              <Box className={styles.box}>
                <img
                  src={require('../../assets/images/uploadsupport.png')}
                  width={140}
                  height={110}
                />
              </Box>
              <p>{t('howToUploadFile')}</p>
            </Box>
            <Box
              className={styles.itembox2}
              style={{ backgroundColor: '#5BFFCE' }}
            >
              <Box className={styles.box}>
                <img
                  src={require('../../assets/images/chatbotsupport.png')}
                  width={140}
                  height={110}
                />
              </Box>
              <p>{t('howToUseChatbot')}</p>
            </Box>
            <Box
              className={styles.itembox2}
              style={{
                backgroundColor: 'var(--BLUE-ARIA)',
                marginBottom: '4%',
              }}
            >
              <Box className={styles.box}>
                <img
                  src={require('../../assets/images/aboutinvite.png')}
                  width={140}
                  height={110}
                />
              </Box>
              <p>{t('aboutInvite')}</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
