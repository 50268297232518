export const styleTextField = {
  '& label.Mui-focused': {
    color: 'var(--GREEN-ARIA)',
  },
  '& label': {
    color: 'var(--TEXT-GRAY-LIGHT)',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Roboto-Light',
    borderRadius: 24,
    '& fieldset': {
      borderColor: 'var(--GREEN-ARIA)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--GREEN-ARIA)',
    },
    '&.Mui-focused fieldset span': {
      color: 'var(--GREEN-ARIA)',
    },
    '&:hover fieldset': {
      border: '2px solid #00E6A1',
    },
  },
  '&:hover .MuiOutlinedInput-root': {
    borderColor: 'var(--GREEN-ARIA)',
  },
}
