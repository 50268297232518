import { SxProps } from '@mui/material'

export const tableRowStyles: SxProps = {
  borderRadius: '25px 8px 8px 25px',
  boxShadow: '0px 7px 6px 0px rgba(0,0,0,0.1)',
  WebkitBoxShadow: '0px 7px 6px 0px rgba(0,0,0,0.1)',
  marginRight: 0,
  padding: '0 auto 0 auto',
  background: 'rgba(255, 255, 255, 0.40)',
}

export const tableRowIsGuestWithoutBorderStyles: SxProps = {
  borderRadius: '25px 8px 8px 25px',
  boxShadow: '0px 7px 6px 0px rgba(0,0,0,0.1)',
  WebkitBoxShadow: '0px 7px 6px 0px rgba(0,0,0,0.1)',
  marginRight: 0,
  padding: '0 auto 0 auto',
  background: 'rgba(255, 255, 255, 0.40)',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(130, 251, 86, 0.10)',
  },
}

export const tableCellRadioStyles: SxProps = {
  fontSize: '16px',
  fontFamily: 'Montserrat',
  borderBottom: '2px solid var(--GREEN-ARIA)',
  borderTop: '2px solid var(--GREEN-ARIA)',
  '&:last-child': {
    borderRight: '2px solid var(--GREEN-ARIA)',
    borderRadius: '0px 8px 8px 0px',
  },
  '&:first-of-type': {
    borderLeft: '2px solid var(--GREEN-ARIA)',
    borderRadius: '25px 0px 0px 25px',
  },
}

export const tableCellStyles: SxProps = {
  fontSize: '16px',
  padding: {
    xl: '16px 84px 16px 24px',
    lg: '16px 44px 16px 14px',
    md: '16px 8px 16px 4px',
    xs: '16px 84px 16px 24px',
  },
  fontFamily: 'Montserrat',
  borderBottom: '2px solid var(--GREEN-ARIA)',
  borderTop: '2px solid var(--GREEN-ARIA)',
  '&:last-child': {
    borderRight: '2px solid var(--GREEN-ARIA)',
    borderRadius: '0px 8px 8px 0px',
  },
  '&:first-of-type': {
    borderLeft: '2px solid var(--GREEN-ARIA)',
    borderRadius: '25px 0px 0px 25px',
  },
}

export const tableRowSkeletonStyles: SxProps = {
  fontSize: '14px',
  fontFamily: 'Montserrat',
  borderTop: '2px solid var(--GREEN-ARIA)',
  backgroundColor: '#FFFFFF',
  '&:last-child': {
    borderRight: '2px solid var(--GREEN-ARIA)',
    borderRadius: '0px 8px 8px 0px',
  },
  '&:first-of-type': {
    borderLeft: '2px solid var(--GREEN-ARIA)',
    borderRadius: '25px 0px 0px 25px',
  },
  padding: '0 auto 0 auto',
  marginRight: 0,
}

export const tableCellSkeletonStyles: SxProps = {
  fontSize: '14px',
  fontFamily: 'Montserrat',
  borderTop: '2px solid var(--GREEN-ARIA)',
  backgroundColor: '#FFFFFF',
  '&:last-child': {
    borderRight: '2px solid var(--GREEN-ARIA)',
    borderRadius: '0px 8px 8px 0px',
  },
  '&:first-of-type': {
    borderLeft: '2px solid var(--GREEN-ARIA)',
    borderRadius: '25px 0px 0px 25px',
  },
  padding: '0 auto 0 auto',
}
