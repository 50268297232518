import { GuestItems } from './GuestItems'
import styles from './SubNavBar.module.scss'
import { useLocation } from 'react-router-dom'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'

const REGEX_PATH = /^\/register\/\d+$/
const SubNavBar = () => {
  const location = useLocation()
  const { isRegisteredGuest } = useIsRegisteredGuest()

  if (REGEX_PATH.test(location.pathname)) return

  return (
    <nav
      className={`${styles.nav} ${
        isRegisteredGuest && styles.navRegisteredGuest
      }`}
    >
      <ul className={styles.navBar}>
        <GuestItems />
      </ul>
    </nav>
  )
}

export default SubNavBar
