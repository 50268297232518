import { RouteProps } from '../../routes/AppRouter'
import styles from './Chatbot.module.scss'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Box } from '@mui/material'

export const Chatbot = (props: RouteProps) => {
  const { t } = useTranslation()
  const [showChatbot, setShowChatbot] = useState<boolean>(false)

  return (
    <Box className={styles.container}>
      {showChatbot ? (
        <Box className={styles.chatbot}>
          <Box className={styles.header}>
            <p className={styles.title}>Chatbot</p>
            <i
              className="fas fa-xmark icon"
              onClick={() => setShowChatbot(!showChatbot)}
            />
          </Box>
          <Box className={styles.scrollBox}>
            <p>{t('chatbotMessage')}</p>
          </Box>
          <Box className={styles.textInput}>
            <input type="text" placeholder={t('typeAmessage')} />
            <img
              src={require('../../assets/images/arrowchat.png')}
              width={23}
              height={23}
            />
          </Box>
        </Box>
      ) : (
        <img
          src={require('../../assets/images/chatbot.png')}
          className={styles.img}
          width={93}
          height={93}
          onClick={() => setShowChatbot(!showChatbot)}
        />
      )}
    </Box>
  )
}
