import { styled } from '@mui/styles'
import { Button } from '@mui/material'

export const ButtonCancel = styled(Button)({
  display: 'flex',
  width: '102px',
  height: '40px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3px',
  borderRadius: '24px',
  border: '1px solid #00E6A1',
  background: '#FFF',
  color: '#00E6A1',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',
  '&:hover': {
    background: '#FFF',
    transform: 'scale(1.05)',
    transition: 'all 0.2s ease-in-out',
  },
})

export const ButtonAccept = styled(Button)({
  display: 'flex',
  width: '102px',
  height: '40px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3px',
  borderRadius: '24px',
  border: '1px solid #00E6A1',
  background: 'var(--GREEN-ARIA)',
  color: '#fff',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',

  '&:hover': {
    background: 'var(--GREEN-ARIA)',
    transform: 'scale(1.05)',
    transition: 'all 0.2s ease-in-out',
  },
})

export const ButtonRegisterOrLogin = styled(Button)({
  display: 'flex',
  height: '40px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3px',
  borderRadius: '24px',
  border: '1px solid #00E6A1',
  background: 'var(--GREEN-ARIA)',
  color: '#fff',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',

  '&:hover': {
    background: 'var(--GREEN-ARIA)',
    transform: 'scale(1.05)',
    transition: 'all 0.2s ease-in-out',
  },
})
