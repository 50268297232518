import { Container } from '../../common/container/Container'

export type ActivityContainerConfig = {
  moduleFullUrl: string
}

export type ActivityProps = {
  parentContainer: Container
  config: ActivityContainerConfig
}

export const ACTIVITY_MODULE = Symbol('ACTIVITY_MODULE')

export const ACTIVITY_API_KEY = Symbol('ACTIVITY_API_KEY')

export const ACTIVITY_SERVICE_KEY = Symbol('ACTIVITY_SERVICE_KEY')
