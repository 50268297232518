export enum DocType {
  Image = 'image',
  PDF = 'pdf',
  Doc = 'doc',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export enum FileType {
  Jpg = 'image/jpeg',
  Png = 'image/png',
  PDF = 'application/pdf',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}
