import { Container, ContainerItem } from '../common/container/Container'
import { ActivityService } from '../modules/activity/services/ActivityService'
import {
  ACTIVITY_API_KEY,
  ACTIVITY_SERVICE_KEY,
  ACTIVITY_MODULE,
  ActivityProps,
} from '../modules/activity/container'
import { ActivityApi } from '../modules/activity/api/ActivityApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: ActivityProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(
    ACTIVITY_API_KEY,
    new ContainerItem({ instance: new ActivityApi() }),
  )

  items.set(
    ACTIVITY_SERVICE_KEY,
    new ContainerItem({
      instance: new ActivityService({ apiKey: ACTIVITY_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getAcitivtyContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[ACTIVITY_MODULE],
      },
    })
  }

  return container
}
