import i18n from '../../../i18n/i18n'

export enum RoleType {
  SystemAdmin = 0,
  Patient,
  Guest,
  Researcher,
  PatientGuest,
}

export const roleTypes = (): Record<RoleType, string> => ({
  [RoleType.SystemAdmin]: i18n.t('systemAdmin'),
  [RoleType.Patient]: i18n.t('patient'),
  [RoleType.Guest]: i18n.t('guest'),
  [RoleType.Researcher]: i18n.t('researcher'),
  [RoleType.PatientGuest]: i18n.t('patientGuest'),
})
