import React from 'react'
import {
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Actions, Field } from './types'
import { v4 as uuidv4 } from 'uuid'
import unchecked from '../../assets/buttons/OFF-Blue.svg'
import checked from '../../assets/buttons/checked.svg'
import {
  tableCellRadioStyles,
  tableCellSkeletonStyles,
  tableCellStyles,
  tableRowIsGuestWithoutBorderStyles,
  tableRowSkeletonStyles,
  tableRowStyles,
} from './StylesMaterialUI'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly isLoading?: boolean
  readonly handlerChecked?: (key: string) => void
  readonly checked: Record<string, boolean>
  addDeleteFile?: (file: File) => void
  isGuest?: boolean
}

export function Body<T extends Record<string, any>>(props: BodyProps<T>) {
  const rows = props.items.map((item, index) => {
    return !props.isLoading ? (
      <TableRow
        onClick={() =>
          props.isGuest
            ? props.handlerChecked &&
              props.handlerChecked(item[props.rowKeyField])
            : null
        }
        sx={props.isGuest ? tableRowIsGuestWithoutBorderStyles : tableRowStyles}
        key={item[props.rowKeyField]}
      >
        <TableCell sx={tableCellRadioStyles}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            sx={{ paddingLeft: 2, display: props.isGuest ? 'none' : '' }}
          >
            <FormControlLabel
              value=""
              control={
                <Checkbox
                  icon={<img src={unchecked} alt="unchecked" />}
                  checkedIcon={<img src={checked} alt="checked" />}
                  key={'props.label' + 'checkbox'}
                  name={'props.label' + 'checkbox'}
                  checked={!!props.checked[item[props.rowKeyField]]}
                  onChange={() =>
                    props.handlerChecked &&
                    props.handlerChecked(item[props.rowKeyField])
                  }
                />
              }
              label=""
            />
          </RadioGroup>
        </TableCell>
        {Object.values(props.fields).map((field, index) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCell
              className={style}
              sx={tableCellStyles}
              key={(field.name as string) + uuidv4()}
            >
              {field.renderFunc
                ? field.renderFunc(field, item)
                : item[field.name].toString()}
            </TableCell>
          )
        })}
      </TableRow>
    ) : (
      <TableRow sx={tableRowSkeletonStyles} key={item[props.rowKeyField]}>
        {Object.values(props.fields).map((field) => {
          const style = field.styleFunc ? field.styleFunc(field, item) : ''
          return (
            <TableCell
              sx={tableCellSkeletonStyles}
              className={style}
              key={(field.name as string) + uuidv4()}
            >
              <Skeleton />
            </TableCell>
          )
        })}
      </TableRow>
    )
  })

  return <TableBody style={{ borderRadius: '25px' }}>{rows}</TableBody>
}
