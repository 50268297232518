import styles from './Dashboard.module.scss'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  URL_ACTIVITY_HISTORY,
  URL_INVITE,
  URL_MEDICAL_RECORD,
  URL_PROFILE,
  URL_SUPPORT,
} from '../../routes/routes-constants'
import { Box, Divider } from '@mui/material'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { Permission } from '../../common/enums/Permissions'
import { useFileUpload } from '../../hooks/useFileUpload'
import { UploadFile } from '../medicalRecord/UploadFile'

type Message = {
  from: string
  msg: string
}

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const Dashboard = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const fileUpload = useFileUpload(loggedUser?.id ?? '')
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const scrollableDivRef = useRef(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null) // Declarar fileInputRef
  const navigate = useNavigate()

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    const aux: Message[] = [
      { from: 'me', msg: '¿Qué sabes sobre mi enfermedad?' },
      {
        from: 'chat',
        msg: 'Todavía no tengo esa información, por favor actualiza tu historial médico',
      },
      { from: 'me', msg: 'vale, ya está' },
      { from: 'chat', msg: 'genial' },
      { from: 'me', msg: '¿Qué sabes sobre mi enfermedad?' },
      {
        from: 'chat',
        msg: 'Todavía no tengo esa información, por favor actualiza tu historial médico',
      },
      { from: 'me', msg: 'vale, ya está' },
      { from: 'chat', msg: 'genial' },
    ]

    setMessages(aux)
  }, [])

  const sendMessage = () => {
    const auxMessages = [...messages]
    auxMessages.push({
      msg: message,
      from: 'me',
    })
    setMessages(auxMessages)
    setMessage('')
  }

  const handlePressKey = (event: {
    key: string
    preventDefault: () => void
  }) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  return (
    <Box className={styles.dashboardContainer}>
      {fileUpload.selectedFile && !fileUpload.closePreview ? (
        <Box className={styles.containerMedicalRecord}>
          <Box>
            <h2 className={styles.titleMedicalRecord}>
              {t('myMedicalRecord')}
            </h2>
            <Divider
              style={{
                border: '2px solid var(--GREEN-FLUOR)',
                width: '111px',
                marginTop: 4,
              }}
            />
          </Box>
          <UploadFile
            file={fileUpload.selectedFile}
            handlePreviewClose={fileUpload.handlePreviewClose}
            loggedUser={loggedUser}
            urlFile={fileUpload.urlFile ?? ''}
          />
        </Box>
      ) : (
        <>
          <Box className={styles.dashboard}>
            <p className={styles.dashTitle}>{t('dashboard')}</p>
            <Box className={styles.linedashboard0}>
              <Box className={styles.linedashboard} />
            </Box>

            <Box className={styles.profilecontainer}>
              <Box
                className={styles.itembox}
                style={{ backgroundColor: '#4EE3F4' }}
                onClick={() => navigate(URL_MEDICAL_RECORD)}
              >
                <Box
                  className={styles.box}
                  style={{ backgroundColor: '#4EE3F4' }}
                >
                  <img
                    src={require('../../assets/images/medicalRecord.png')}
                    width={140}
                    height={110}
                  />
                </Box>
                <p>{t('myMedicalRecord')}</p>
              </Box>

              <Box className={styles.itembox}>
                <Box
                  className={styles.box}
                  onClick={() => fileInputRef.current?.click()}
                >
                  <img
                    src={require('../../assets/images/upload.png')}
                    width={140}
                    height={110}
                  />
                  <input
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    type="file"
                    onChange={fileUpload.handleFileSelect}
                  />
                </Box>
                <p>{t('uploadFile')}</p>
              </Box>

              <Box
                className={styles.itembox}
                style={{ backgroundColor: 'var(--GREEN-ARIA)' }}
                onClick={() => navigate(URL_PROFILE)}
              >
                <Box
                  className={styles.box}
                  style={{ backgroundColor: 'var(--GREEN-ARIA)' }}
                >
                  <img
                    src={require('../../assets/images/perfil.png')}
                    width={140}
                    height={110}
                  />
                </Box>
                <p>{t('myProfile')}</p>
              </Box>
            </Box>
            <Box
              className={styles.profilecontainer}
              style={{ marginTop: '3.5%' }}
            >
              {loggedUserService.userCan(Permission.invite) && (
                <Box
                  className={styles.itembox}
                  style={{ backgroundColor: '#5BFFCE' }}
                  onClick={() => navigate(URL_INVITE)}
                >
                  <Box
                    className={styles.box}
                    style={{ backgroundColor: '#5BFFCE' }}
                  >
                    <img
                      src={require('../../assets/images/invite.png')}
                      width={140}
                      height={110}
                    />
                  </Box>
                  <p>{t('invite')}</p>
                </Box>
              )}
              {loggedUserService.userCan(Permission.viewActivityHistory) && (
                <Box
                  className={styles.itembox}
                  style={{ backgroundColor: 'var(--BLUE-ARIA)' }}
                  onClick={() => navigate(URL_ACTIVITY_HISTORY)}
                >
                  <Box
                    className={styles.box}
                    style={{ backgroundColor: 'var(--BLUE-ARIA)' }}
                  >
                    <img
                      src={require('../../assets/images/activityHistory.png')}
                      width={140}
                      height={110}
                    />
                  </Box>
                  <p>{t('activityHistory')}</p>
                </Box>
              )}
              <Box
                className={styles.itembox}
                style={{ backgroundColor: '#98FE72' }}
              >
                <Box
                  className={styles.box}
                  style={{ backgroundColor: '#98FE72' }}
                  onClick={() => navigate(URL_SUPPORT)}
                >
                  <img
                    src={require('../../assets/images/soporte.png')}
                    width={140}
                    height={110}
                  />
                </Box>
                <p>{t('support')}</p>
              </Box>
            </Box>
          </Box>
          {loggedUserService.userCan(Permission.usePatientChatbot) && (
            <Box className={styles.chat}>
              <p className={styles.chatTitle}>{t('Chatbot')}</p>
              <Box className={styles.linechat0}>
                <Box className={styles.linechat} />
              </Box>
              <Box className={styles.chatbox}>
                <Box className={styles.boxScroll} ref={scrollableDivRef}>
                  {messages.map((item, i) => (
                    <Box key={item.msg + i}>
                      {item.from === 'chat' ? (
                        <Box className={styles.msgBox}>
                          <img
                            src={require('../../assets/images/chaticon.png')}
                            width={41}
                            height={41}
                          />
                          <Box className={styles.bocataChatbotAZUL}>
                            <img
                              className={styles.rectangle}
                              alt="Rectangle"
                              src="https://c.animaapp.com/6Dqd5HAj/img/rectangle-3-1.svg"
                            />
                            <Box className={styles.frame}>
                              <Box className={styles.loremIpsumAte}>
                                {item.msg}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box className={styles.msgBoxMe}>
                          <Box className={styles.bocataChatbotVerde}>
                            <Box className={styles.frame}>
                              <Box className="lorem-ipsum-ate">{item.msg}</Box>
                            </Box>
                            <img
                              className={styles.rectangle}
                              alt="Rectangle"
                              src="https://c.animaapp.com/k5cNWq7w/img/rectangle-3-1.svg"
                            />
                          </Box>
                          <img
                            src={require('../../assets/images/chatmeicon.png')}
                            width={41}
                            height={41}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box className={styles.boxWrite}>
                  <input
                    id="chatinput"
                    onKeyDown={handlePressKey}
                    type="text"
                    className={styles.inputChatDashboard}
                    placeholder={t('typeAmessage')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <img
                    className={styles.img}
                    onClick={sendMessage}
                    src={require('../../assets/images/arrowchat.png')}
                    width={23}
                    height={23}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
