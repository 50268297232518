import { Activity } from '../models/Activity'
import { Container, IInit } from '../../../common/container/Container'
import { ActivityContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { ActivityDTO, toModel } from '../models/ActivityDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList, emptyList } from '../../../common/models/ItemList'

export interface IActivityApi extends IInit {
  getByID(id: string | null): Observable<Activity | undefined>

  getByUserID(
    id: string | null,
    q: Query<Activity>,
  ): Observable<ItemList<Activity>>

  getAllByUserID(id: string | null): Observable<ItemList<Activity>>

  getFilteredList(q: Query<Activity>): Observable<Activity[]>

  add(e: ActivityDTO): Observable<Activity | undefined>

  update(e: ActivityDTO): Observable<Activity | undefined>

  delete(id: string): Observable<boolean>
}

export class ActivityApi implements IActivityApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (
      this._container.config as ActivityContainerConfig
    ).moduleFullUrl
  }

  getByID(id: string | null): Observable<Activity | undefined> {
    return this._httpClient.get<Activity>({ url: `${this._url}/` + id }).pipe(
      map<ActivityDTO, Activity>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  getByUserID(
    id: string | null,
    q: Query<Activity>,
  ): Observable<ItemList<Activity>> {
    return this._httpClient
      .get<ItemList<Activity>>({
        url: prepareURL(`${this._url}/byUser/${id}`, q),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Activity>())
        }),
      )
  }

  getAllByUserID(id: string | null): Observable<ItemList<Activity>> {
    return this._httpClient
      .get<ItemList<Activity>>({ url: `${this._url}/byUserAll/` + id })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Activity>())
        }),
      )
  }

  getFilteredList(q: Query<Activity>): Observable<Activity[]> {
    return this._httpClient
      .get<Activity[]>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        }),
      )
  }

  add(e: ActivityDTO): Observable<Activity | undefined> {
    return this._httpClient.post<Activity>({ url: this._url, body: e }).pipe(
      map<ActivityDTO, Activity>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  update(e: ActivityDTO): Observable<Activity | undefined> {
    return this._httpClient.put<Activity>({ url: this._url, body: e }).pipe(
      map<ActivityDTO, Activity>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/` + id }).pipe(
      catchError((err) => {
        return of(err)
      }),
    )
  }
}
