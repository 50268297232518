import { FileType } from '../modules/files/enum/FileEnums'
import { FileDTO } from '../modules/files/models/FileDTO'

const useIsDocFile = (
  file: FileDTO | null,
  fileEdit: FileDTO | null,
): boolean => {
  return (
    file?.type === FileType.Doc ||
    file?.type === FileType.Docx ||
    fileEdit?.type === FileType.Doc ||
    fileEdit?.type === FileType.Docx
  )
}

export default useIsDocFile
