import { Permission } from '../../common/enums/Permissions'
import {
  URL_CHATBOT,
  URL_DASHBOARD_RESEARCHER,
  URL_MEDICAL_RECORD,
  URL_PATIENT_LIST,
} from '../../routes/routes-constants'
import styles from './SubNavBar.module.scss'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import chatbotIcon from '../../assets/sub-navbar/chatbot.svg'
import chatbotIconActive from '../../assets/sub-navbar/chatbotActive.svg'
import dashboardIcon from '../../assets/sub-navbar/dashboard.svg'
import dashboardIconActive from '../../assets/sub-navbar/dashboardActive.svg'
import medicalRecordIcon from '../../assets/sub-navbar/medicalRecord.svg'
import medicalRecordIconActive from '../../assets/sub-navbar/medicalRecordActive.svg'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { useUser } from '../../common/utils/GlobalSelectedUser'
import useIsRegisteredGuest from '../../hooks/useIsRegisteredGuest'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

type LoggedItems = {
  permission: Permission
  url: string
  text: string
  className?: string
  icon?: {
    inactiveIcon: string
    activeIcon: string
  }
  hidden: boolean
}

export const GuestItems = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { selectedUser } = useUser()
  const { isRegisteredGuest } = useIsRegisteredGuest()

  const getItemIcon = (item: LoggedItems) => {
    if (!item.icon) {
      return ''
    }
    const { activeIcon, inactiveIcon } = item.icon
    return location.pathname === item.url ? activeIcon : inactiveIcon
  }

  const loggedItems: LoggedItems[] = [
    {
      hidden: isRegisteredGuest ? !selectedUser : false,
      permission: Permission.usePatientChatbot,
      url: URL_CHATBOT,
      text: 'Chatbot',
      className:
        location.pathname === URL_CHATBOT
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
      icon: {
        inactiveIcon: chatbotIcon,
        activeIcon: chatbotIconActive,
      },
    },
    {
      hidden: isRegisteredGuest ? !selectedUser : false,
      permission: Permission.viewMedicalHistory,
      url: URL_MEDICAL_RECORD,
      text: t('myMedicalRecord'),
      className:
        location.pathname === URL_MEDICAL_RECORD
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
      icon: {
        inactiveIcon: medicalRecordIcon,
        activeIcon: medicalRecordIconActive,
      },
    },
    {
      hidden: isRegisteredGuest ? !selectedUser : false,
      permission: Permission.viewGeneralDashboard,
      url: URL_DASHBOARD_RESEARCHER,
      text: t('dashboard'),
      className:
        location.pathname === URL_DASHBOARD_RESEARCHER
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
      icon: {
        inactiveIcon: dashboardIcon,
        activeIcon: dashboardIconActive,
      },
    },
    {
      hidden: !isRegisteredGuest,
      permission: Permission.seePatientList,
      url: URL_PATIENT_LIST,
      text: t('patientList'),
      className:
        location.pathname === URL_PATIENT_LIST
          ? `${styles.defaultLinks} ${styles.active}`
          : styles.defaultLinks,
    },
  ]

  return (
    <>
      {loggedItems.map((item) => (
        <ListItem
          key={item.url}
          url={item.url}
          text={item.text}
          className={item.className ? item.className : ''}
          permission={item.permission}
          icon={getItemIcon(item)}
          hidden={item.hidden}
        />
      ))}
    </>
  )
}

export const ListItem = (props: {
  permission: Permission
  url: string
  text: string
  className?: string
  icon?: string
  hidden: boolean
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const innerWidth = window.innerWidth

  if (!loggedUserService.userCan(props.permission)) {
    return <></>
  }

  return (
    <li className={styles.guestLi} key={props.url}>
      <Link
        style={{
          visibility: props.hidden ? 'hidden' : 'visible',
        }}
        onClick={() => navigate(props.url)}
        className={`${styles.links} ${props.className}`}
      >
        {props.icon && (
          <img
            src={props.icon}
            alt={props.text}
            width={innerWidth < 844 ? 40 : ''}
          />
        )}
        {props.url !== URL_PATIENT_LIST
          ? innerWidth > 843 && t(props.text)
          : t(props.text)}
      </Link>
    </li>
  )
}
