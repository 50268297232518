import useLoggedInUser from './useLoggedInUser'
import { RoleType } from '../modules/users/enums/RoleEnum'

const useIsRegisteredGuest = () => {
  const { user } = useLoggedInUser()
  const isRegisteredGuest =
    user?.roles.toString() === RoleType.PatientGuest.toString()

  return {
    isRegisteredGuest,
  }
}

export default useIsRegisteredGuest
