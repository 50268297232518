import {
  URL_ABOUTUS,
  URL_GUESTS,
  URL_LOGIN,
  URL_REGISTER,
} from '../../routes/routes-constants'
import styles from './Navbar.module.scss'
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import { Permission } from '../../common/enums/Permissions'
import { useTranslation } from 'react-i18next'

interface GeneralItemsProps {
  selectStyle: { control: (base: any, state: { isFocused: any }) => any }
  options: { value: string; label: string }[]
  handleChangeLanguage: (value: string) => void
  lan: any
}
export const GeneralItems = ({
  lan,
  options,
  handleChangeLanguage,
  selectStyle,
}: GeneralItemsProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const generalItems = [
    {
      url: URL_ABOUTUS,
      text: t('aboutUS'),
      className:
        location.pathname === URL_ABOUTUS
          ? `${styles.aboutUs} ${styles.active}`
          : styles.aboutUs,
    },
    {
      url: URL_REGISTER,
      text: t('register'),
      className:
        location.pathname === URL_REGISTER
          ? `${styles.registerButton} ${styles.active}`
          : styles.registerButton,
    },
    {
      url: URL_LOGIN,
      text: t('login'),
      className:
        location.pathname === URL_LOGIN
          ? `${styles.loginButton} ${styles.active}`
          : styles.loginButton,
    },
    {
      permission: Permission.seeGuestSection,
      url: URL_GUESTS,
      text: t('guests'),
      className:
        location.pathname === URL_GUESTS
          ? `${styles.guestButton} ${styles.active}`
          : styles.guestButton,
    },
  ]
  return (
    <>
      {generalItems.map((item) => (
        <GenerateGeneralItems
          key={item.url}
          url={item.url}
          text={item.text}
          className={item.className}
        />
      ))}
      <li key={'optionGeneral'}>
        <Select
          options={options}
          styles={selectStyle}
          value={lan}
          onChange={(v) => handleChangeLanguage(v.value)}
        />
      </li>
    </>
  )
}

const GenerateGeneralItems = (props: {
  url: string
  text: string
  className?: string
  permission?: Permission
}) => {
  const navigate = useNavigate()
  return (
    <li key={props.url} style={{ gap: '32px' }}>
      <Link
        onClick={async () => navigate(props.url)}
        className={`${styles.links} ${props.className}`}
      >
        {props.text}
      </Link>
    </li>
  )
}
