import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { UserService } from '../../modules/users/services/UserService'
import styles from './Invite.module.scss'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { PhoneCodeSelector } from '../../components/phone-code-selector/PhoneCodeSelector'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)

export const Invite = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [phone, setPhone] = useState<string>('')

  const handleSendCode = () => {
    if (!phone || phone.length < 12) {
      enqueueSnackbar(t('invalidPhone'), { variant: 'error' })
      return
    }
    setIsLoading(true)
    userService
      .sendInvitationCode(phone, loggedUserService.get()?.email ?? '')
      .subscribe((res) => {
        setIsLoading(false)
        if (res) {
          enqueueSnackbar(t('invitationCorrectlySent'), { variant: 'success' })
        } else {
          enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
        }
      })
    setPhone('')
  }

  const handlePressKey: (event: React.KeyboardEvent<HTMLDivElement>) => void = (
    event,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendCode()
    }
  }

  return (
    <Box className={styles.profileI}>
      <Box className={styles.inviteTitle}>
        <p className={styles.dashTitle}>{t('invite')}</p>
        <Box className={styles.linedashboard0}>
          <Box className={styles.linedashboard} />
        </Box>
        <img
          src={require('../../assets/images/arrow.png')}
          width={130}
          height={130}
          className={styles.arrow}
          alt={'arrow'}
        />
      </Box>
      <Box className={styles.inviteBox}>
        <Box className={styles.inviteBox2}>
          <img
            src={require('../../assets/images/inviterounded.png')}
            width={110}
            height={110}
            className={styles.imgInvite}
            alt={'inviter icon'}
          />
          <p className={styles.inviteTitle2}>{t('inviteDescription')}</p>
          <PhoneCodeSelector
            onSelectCode={(code: string) => {
              setPhone(code)
            }}
            handlePressKey={handlePressKey}
            phone={phone}
          />
          {!isLoading && (
            <Box className={styles.registerButton} onClick={handleSendCode}>
              {t('send')}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
