import { USERS_MODULE } from '../modules/users'
import { FILES_MODULE } from '../modules/files'
import { ACTIVITY_MODULE } from '../modules/activity'
import { INVITATION_MODULE } from '../modules/invitation '

// const apiURL = 'http://localhost:8000'
const apiURL = 'https://aria.aicrumit.com/backend'

export const moduleURLs = {
  [USERS_MODULE]: `${apiURL}/users`,
  [FILES_MODULE]: `${apiURL}/files`,
  [ACTIVITY_MODULE]: `${apiURL}/activity`,
  [INVITATION_MODULE]: `${apiURL}/invitation`,
}
