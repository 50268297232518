export const PhoneData: Record<string, string> = {
  US: '+1',
  CA: '+1',
  GB: '+44',
  AU: '+61',
  ES: '+34',
  MX: '+52',
  AR: '+54',
  CL: '+56',
  CO: '+57',
  PE: '+51',
  VE: '+58',
  EC: '+593',
  GT: '+502',
  CU: '+53',
  BO: '+591',
  DO: '+1',
  HN: '+504',
  PY: '+595',
  NI: '+505',
  SV: '+503',
  CR: '+506',
  PA: '+507',
  PR: '+1',
  UY: '+598',
  FR: '+33',
  IT: '+39',
  DE: '+49',
  NL: '+31',
  BE: '+32',
  SE: '+46',
  NO: '+47',
  DK: '+45',
  FI: '+358',
  PL: '+48',
  AT: '+43',
  CH: '+41',
  GR: '+30',
  RO: '+40',
}
