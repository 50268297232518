import { SxProps } from '@mui/material'

export const selectStyle: SxProps = {
  '.MuiOutlinedInput-notchedOutline': {
    border: 'solid 1px var(--GREEN-ARIA)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'solid 2px var(--GREEN-ARIA)',
  },
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'solid 1px var(--GREEN-ARIA)',
    },
  },
}
