import { RouteProps } from '../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { Box, Typography } from '@mui/material'
import { AppTable } from '../../components/table'
import { Field, Pager, Search, SearchValue } from '../../components/table/types'
import {
  fromModel,
  User,
  UserDTO,
  UserQuery,
} from '../../modules/users/models/User'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { useTranslation } from 'react-i18next'
import { Searcher } from '../../components/table/Searcher'

import styles from './PatientList.module.scss'
import { GenericHeader } from '../../components/header/GenericHeader'
import { useUser } from '../../common/utils/GlobalSelectedUser'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import { lastValueFrom } from 'rxjs'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
export const PatientList = (props: RouteProps) => {
  const { t } = useTranslation()
  const { setSelectedUser } = useUser()
  const { user } = useLoggedInUser()
  const { selectedUser } = useUser()
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [usersPerPage, setUsersPerPage] = useState<number>(10)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [users, setUsers] = useState<ItemList<User>>(emptyList())
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    selectedUser?.id ? { [selectedUser.id]: true } : {},
  )
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    userService
      .getUsersInvitationsByUserID(
        new Query({
          query: [
            new QueryParam('id', user?.id ?? ''),
            ...searcherQuery(searcher),
          ],
        }),
      )
      .subscribe((res) => {
        if (!res) return
        setUsers(res)
        setCount(res.count)
      })
  }, [searcher])

  useEffect(() => {
    setIsSearch(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: usersPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, usersPerPage])

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setSearcher(svs)
      setIsSearch(!isSearch)
    },
  }

  const getUserById = async (key: string) => {
    const userById$ = userService.getByID(key)
    const user = await lastValueFrom(userById$)
    if (!user) return
    localStorage.setItem('selectedUser', JSON.stringify(fromModel(user)))
    setSelectedUser(fromModel(user))
  }

  const handleCheckedChange = async (key: string) => {
    setCheckedItems((prevCheckedItems) => ({
      [key]: !prevCheckedItems[key],
    }))
    await getUserById(key)
  }

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (Number.isNaN(event.target.value)) {
      setUsersPerPage(10)
      return
    }
    setUsersPerPage(Number.parseInt(event.target.value))
  }

  const handleUserSelect = (user: UserDTO) => {
    setSelectedUser(user)
  }

  const fields: Field<User>[] = [
    {
      name: 'createdAt',
      label: t('registrationDate'),
      renderFunc: (f, item) => {
        return (
          <Typography
            onClick={() => handleUserSelect(item)}
            className={styles.registrationDateText}
          >
            {new Date(item.createdAt).toLocaleDateString()}
          </Typography>
        )
      },
    },
    {
      name: 'firstName',
      label: t('name'),
    },
    {
      name: 'lastName',
      label: t('lastName'),
    },
    {
      name: 'idDni',
      label: t('ID'),
    },
    {
      name: 'email',
      label: t('email'),
    },
  ]

  return (
    <Box className={styles.container}>
      <GenericHeader title={'patientList'} />
      <Box className={styles.content}>
        <Searcher search={search} />
        <AppTable
          fields={fields}
          items={users.items}
          rowKeyField={'id'}
          handlerChecked={handleCheckedChange}
          checked={checkedItems}
          pager={pager}
        />
      </Box>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[],
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs
    .filter((sv) => sv.value)
    .map((sv) => ({ name: sv.name, value: sv.value as string }))
