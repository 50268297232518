import useLoggedInUser from './useLoggedInUser'
import { Permission } from '../common/enums/Permissions'
import { RoleType } from '../modules/users/enums/RoleEnum'

const useIsGuestWithoutRegister = () => {
  const { user } = useLoggedInUser()
  const isGuestWithoutRegister =
    user?.permissions.includes(Permission.seeGuestSection) &&
    user?.roles.toString() !== RoleType.PatientGuest.toString()

  return {
    isGuestWithoutRegister,
  }
}

export default useIsGuestWithoutRegister
