import i18n from '../../../i18n/i18n'

export enum ActivityType {
  FileUpload = 0,
  MedicalVisualization,
  Inivitation,
  FileUpdate,
}

export const activityTypes = (): Record<ActivityType, string> => ({
  [ActivityType.FileUpload]: i18n.t('fileUploadActivity'),
  [ActivityType.MedicalVisualization]: i18n.t('medicalVisualizationAcitivity'),
  [ActivityType.Inivitation]: i18n.t('invitationActivity'),
  [ActivityType.FileUpdate]: i18n.t('fileUpdateActivity'),
})
