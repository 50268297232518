import { ActivityType } from '../enums/ActivityEnum'
import { ActivityDTO } from './ActivityDTO'

export class Activity {
  private readonly _id: string | undefined
  private readonly _date: Date
  private readonly _userID: string
  private readonly _type: ActivityType
  private readonly _userName: string

  constructor(p: ActivityDTO) {
    this._id = p.id
    this._date = p.date
    this._userID = p.userID
    this._type = p.type
    this._userName = p.userName
  }

  get id(): string | undefined {
    return this._id
  }

  get date(): Date {
    return this._date
  }

  get userID(): string {
    return this._userID
  }

  get type(): ActivityType {
    return this._type
  }

  get userName(): string {
    return this._userName
  }
}
