import { LoggedUserDTO } from './LoggedUserDTO'

export class LoggedUser {
  private readonly _id: string
  private _firstName: string
  private _lastName: string
  private readonly _createdAt: Date
  private readonly _email: string
  private readonly _phone: string
  private readonly _cookieID: string
  private readonly _sessionExpires: Date
  private _language: string
  private _roles: string
  private _permissions: string[]

  constructor(p: LoggedUserDTO) {
    this._id = p.id
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._createdAt = p.createdAt
    this._email = p.email
    this._phone = p.phone
    this._sessionExpires = p.sessionExpires
    this._language = p.language
    this._roles = p.roles
    this._cookieID = p.cookieID
    this._permissions = p.permissions
  }

  get id(): string {
    return this._id
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get firstName(): string {
    return this._firstName
  }

  get cookieID(): string {
    return this._cookieID
  }

  get permissions(): string[] {
    return this._permissions
  }

  set permissions(value: string[]) {
    this._permissions = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get email(): string {
    return this._email
  }

  get phone(): string {
    return this._phone
  }

  get sessionExpires(): Date {
    return this._sessionExpires
  }

  get language(): string {
    return this._language
  }

  set language(value: string) {
    this._language = value
  }

  get roles(): string {
    return this._roles
  }

  set roles(value: string) {
    this._roles = value
  }
}
