import { Activity } from './Activity'
import { v4 as uuidv4 } from 'uuid'
import { ActivityType } from '../enums/ActivityEnum'

export interface ActivityDTO {
  id?: string
  date: Date
  userID: string
  type: ActivityType
  userName: string
}

export function emptyFileDTO(): ActivityDTO {
  return {
    id: uuidv4(),
    date: new Date(),
    userID: '',
    type: 0, // TODO BEA THIS FROM enums
    userName: '',
  }
}

export function fromModel(f: Activity): ActivityDTO {
  return {
    id: f.id,
    date: f.date,
    userID: f.userID,
    type: f.type,
    userName: f.userName,
  }
}

export function toModel(d: ActivityDTO): Activity {
  return new Activity(d)
}
