import React from 'react'
import { TablePagination } from '@mui/material'
import { Pager } from './types'
import { useTranslation } from 'react-i18next'

const rowsPerPageOptions = [10, 20, 50, 100]

type PaginationProps = Pager

export function Pagination(props: PaginationProps) {
  const { t } = useTranslation()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage ?? rowsPerPageOptions[0],
  )

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) => {
        return ''
      }}
      labelRowsPerPage={
        <span
          style={{ color: 'var(--TEXT-GRAY-DARK)', fontFamily: 'Montserrat' }}
        >
          {t('resultForPage')}
        </span>
      }
      count={props.count}
      onPageChange={props.handleChangePage}
      page={props.page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      sx={{ borderBottom: 0 }}
      SelectProps={{
        style: {
          border: '1px solid var(--GREEN-ARIA)',
          borderRadius: '3px',
          fontFamily: 'Montserrat',
          fill: 'var(--GREEN-ARIA)',
        },
      }}
      backIconButtonProps={{
        style: {
          color: props.page === 0 ? '#b5b8c4' : 'var(--GREEN-ARIA)',
          border: 'none',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
        },
      }}
      nextIconButtonProps={{
        style: {
          color:
            props.count - (props.page + 1) * rowsPerPage <= 0
              ? '#b5b8c4'
              : 'var(--GREEN-ARIA)',
          border: 'none',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
        },
      }}
    />
  )
}
