import {
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material'
import { selectStyle } from './stylesMaterialUI'
import styles from './PhoneCodeSelector.module.scss'
import { PhoneData } from './PhoneData'
import React from 'react'

interface CountrySelectProps {
  selectedCountry: string
  handleCountryChange: (event: SelectChangeEvent) => void
}

export const CountrySelect: React.FC<CountrySelectProps> = ({
  selectedCountry,
  handleCountryChange,
}) => {
  return (
    <FormControl variant="outlined">
      <Select
        className={styles.selectCountry}
        value={selectedCountry}
        onChange={(e) => handleCountryChange(e)}
        size={'small'}
        sx={selectStyle}
      >
        {Object.keys(PhoneData).map((country) => (
          <MenuItem
            key={country}
            value={country}
            className={styles.menuItemSelect}
          >
            <ListItemIcon className={styles.itemIcon}>
              <span className={`fi fi-${country.toLowerCase()} fis`} />
            </ListItemIcon>
            <ListItemText className={styles.textSelect} primary={country} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
